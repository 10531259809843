import ErrorMessage from "../components/utils/ErrorMessage";

function Page404({ statusCode }) {
  const error = {};
  error.message = statusCode
    ? `An error ${statusCode} occurred on server`
    : "Page not found";
  return <ErrorMessage error={error} />;
}

export default Page404;
